import Axios from "axios";
import dateFormat from "dateformat";
import jsPDF from "jspdf";
import QRCode from "qrcode";

export function getSaleDate(tiers, id) {
  if (!Array.isArray(tiers)) {
    tiers = [tiers];
  }

  for (const tier of tiers) {
    for (const saleDate of tier.saleDates) {
      if (saleDate.id === id) {
        return saleDate;
      }
    }
  }

  return null;
}

export function getVenueAccessDate(tiers, id) {
  if (!Array.isArray(tiers)) {
    tiers = [tiers];
  }

  for (const tier of tiers) {
    for (const venueAccessDate of tier.venueAccessDates) {
      if (venueAccessDate._id === id) {
        return venueAccessDate;
      }
    }
  }

  return null;
}

export function getVenueAccessTime(
  tiers,
  venueAccessDateID,
  venueAccessTimeID
) {
  if (!Array.isArray(tiers)) {
    tiers = [tiers];
  }

  const date = getVenueAccessDate(tiers, venueAccessDateID);

  if (date) {
    for (const venueAccessTime of date.times) {
      if (venueAccessTime.id === venueAccessTimeID) {
        return venueAccessTime;
      }
    }
  }

  return null;
}

/**
 * Essentially this is a way to lookup the higher-level ids for each quantity related id
 */
export function createFlatTierMap(tiers) {
  if (!Array.isArray(tiers)) {
    tiers = [tiers];
  }

  const map = {};

  for (const tier of tiers) {
    for (const saleDate of tier.saleDates) {
      map[saleDate.id] = {
        tier,
        saleDateID: saleDate.id,
      };
    }

    if (tier.type !== "General Tier") {
      for (const venueAccessDate of tier.venueAccessDates) {
        map[venueAccessDate.id] = {
          tier,
          venueAccessDateID: venueAccessDate.id,
        };

        if (tier.type === "Date and Time") {
          for (const venueAccessTime of venueAccessDate.times) {
            map[venueAccessDate.id] = {
              tier,
              venueAccessDateID: venueAccessDate.id,
              venueAccessTimeID: venueAccessTime.id,
            };
          }
        }
      }
    }
  }

  return map;
}

export function viewTicketStock(
  ticket,
  oldEvent,
  ticketTemplate,
  ticketLogoTemplate,
  onComplete
) {
  let ctx = ticketTemplate.getContext("2d");
  let ctx1 = ticketLogoTemplate.getContext("2d");

  const image = new Image(100, 100);
  const image1 = new Image();

  let iconData = "";
  image1.crossOrigin = "anonymous";
  image1.onload = drawImage1;
  image.onload = drawImage;
  let iconType = "";

  if (ticket.icon) {
    Axios.post(`${process.env.VUE_APP_SERVICE_URL}planners/convert-to-base64`, {
      imgPath: ticket.icon,
    })
      .then((response) => {
        image1.src = document.getElementById("img-ticket-stock").src;
        iconType = response.data.type;
      })
      .then((response) => {
        image.src = document.getElementById("img-ticket-stock").src;
      });
  } else {
    image.src = document.getElementById("img-ticket-stock").src;
  }

  function drawImage1() {
    let divRatio, newHeight, newWidth;

    if (image1.height > image1.width) {
      divRatio = image1.height / ticketLogoTemplate.height;
      newHeight = ticketLogoTemplate.height;
      newWidth = image1.width / divRatio;
    } else {
      divRatio = image1.width / ticketLogoTemplate.width;
      newWidth = ticketLogoTemplate.width;
      newHeight = image1.height / divRatio;
    }

    ctx1.drawImage(this, 0, 0, newWidth, newHeight);
    iconData = ticketLogoTemplate.toDataURL();
  }

  function drawImage() {
    ticketTemplate.width = this.naturalWidth;
    ticketTemplate.height = this.naturalHeight;

    ctx.drawImage(this, 0, 0);

    let doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: ["144", "396"],
    });

    let tiers = oldEvent.tiers;
    let tier;

    for (let x = 0; x < tiers.length; x++) {
      if (tiers[x].name == ticket.tier) {
        tier = tiers[x];
      }
    }

    for (let x = 0; x < ticket.ticketNumbers.length; x++) {
      // ICON
      if (iconData && iconType) {
        doc.addImage(iconData, iconType, 8, 179, 50, 50, "", "FAST", -90);
        doc.addImage(iconData, iconType, 118, 371, 23, 23, "", "FAST");
      }

      // PRICE
      doc.setLineWidth(2);
      doc.line(6, 65, 138, 65);
      doc.setFontSize(9);

      //EVENT NAME
      doc.setFont("Helvetica", "", "bold");
      doc.text(oldEvent.name, 130, 70, -90);
      doc.setFont("Helvetica", "", "normal");

      // doc.text(oldEvent.venue, 119, 70, -90);

      //VENUE ADDRESS
      if (oldEvent.privateVenue) {
        doc.text("Secret Venue", 108, 70, -90);
      } else {
        let splitAddress = oldEvent.venue.split(",");
        doc.text(
            oldEvent.venue + ", " + oldEvent.venueAddress,
          108,
          70,
            -90
        );
        // doc.text(oldEvent.venueAddress.split(",")[0], 108, 70, -90);
      }

      //TIER TYPE
      doc.text(ticket.tier, 97, 70, -90);

      //EVENT DATE
      if (oldEvent.isRecurring) {
        let dates = getVenueAccessDate(oldEvent.tiers, ticket.venueAccessDateID);
        doc.text(
            dateFormat(
                parseInt(dates.start.$date.$numberLong),
                "ddd, mmm dS, yyyy"
            ) +
            " - " +
            dateFormat(
                parseInt(dates.end.$date.$numberLong),
                "ddd, mmm dS, yyyy"
            ),
            86,
            70,
            -90
        );

        //VENUE TIME
        doc.text(
            dateFormat(parseInt(dates.start.$date.$numberLong), "h:MM TT") +
            " - " +
            dateFormat(parseInt(dates.end.$date.$numberLong), "h:MM TT"),
            75,
            70,
            -90
        );
      } else {
        doc.text(
          dateFormat(
            parseInt(oldEvent.start.$date.$numberLong),
            "ddd, mmm dS, yyyy"
          ) +
            " - " +
            dateFormat(
              parseInt(oldEvent.end.$date.$numberLong),
              "ddd, mmm dS, yyyy"
            ),
          86,
          70,
          -90
        );

        //VENUE TIME
        doc.text(
          dateFormat(parseInt(oldEvent.start.$date.$numberLong), "h:MM TT") +
            " - " +
            dateFormat(parseInt(oldEvent.end.$date.$numberLong), "h:MM TT"),
          75,
          70,
          -90
        );
      }

      //total with tax
      doc.setFont("Helvetica", "", "bold");
      doc.text("Total w/ Tax: $", 64, 227, -90);
      doc.setFont("Helvetica", "", "normal");
      doc.text(tier.price.toString(), 64, 289, -90);

      //TIER purchased by
      doc.setFont("Helvetica", "", "bold");

      doc.text("Purchased By:", 8, 70, -90);
      doc.setFont("Helvetica", "", "normal");

      doc.text(ticket.name, 8, 132, -90);

      //VENUE invoice
      doc.setFont("Helvetica", "", "bold");

      doc.text("Inv. #:", 19, 70, -90);
      doc.setFont("Helvetica", "", "normal");

      doc.text(ticket.invoice, 19, 97, -90);

      //EVENT Tier
      doc.text(ticket.tier, 30, 90, -90);
      doc.setFont("Helvetica", "", "bold");
      doc.text("Tier:", 30, 70, -90);
      doc.setFont("Helvetica", "", "normal");

      //TICKET NUMBER MAIN
      doc.text(ticket.ticketNumbers[x].toString(), 41, 106, -90);
      doc.setFont("Helvetica", "", "bold");

      doc.text("Ticket #:", 41, 70, -90);
      doc.setFont("Helvetica", "", "normal");

      doc.setFontSize(7);

      //VEVENT DATE
      if (oldEvent.isRecurring) {
        let dates = getVenueAccessDate(oldEvent.tiers, ticket.venueAccessDateID);
        doc.text(
            dateFormat(parseInt(dates.start.$date.$numberLong), "mmm d/yy") +
            " - " +
            dateFormat(parseInt(dates.end.$date.$numberLong), "mmm d/yy"),
            5,
            392
        );
      } else {
        doc.text(
            dateFormat(parseInt(oldEvent.start.$date.$numberLong), "mmm d/yy") +
            " - " +
            dateFormat(parseInt(oldEvent.end.$date.$numberLong), "mmm d/yy"),
            5,
            392
        );
      }


      //TIER TYPE
      doc.text(ticket.tier, 5, 384);

      //TICKET NUMBER

      doc.text(ticket.ticketNumbers[x].toString(), 32, 376);
      doc.setFont("Helvetica", "", "bold");
      doc.text("Ticket #:", 5, 376);

      if (x < ticket.ticketNumbers.length - 1) {
        doc.addPage();
      }
    }

    image1.src = "";
    ctx1.clearRect(0, 0, ticketLogoTemplate.width, ticketLogoTemplate.height);

    const blobPDF = new Blob([doc.output("blob")], {
      type: "application/pdf",
    });
    const blobUrl = URL.createObjectURL(blobPDF);

    const fileName = "tickets.pdf";
    const pdf = blobUrl;

    onComplete({ pdf, fileName });
  }
}

export function viewTicket(
  ticket,
  self,
  ticketTemplate,
  ticketLogoTemplate,
  address,
  printTicket = false,
  comps = false,
  onComplete
) {
  let doc = new jsPDF({
    // unit: "px",
    format: "a4",
  });

  let ctx = ticketTemplate.getContext("2d");
  let ctx1 = ticketLogoTemplate.getContext("2d");

  const image = new Image(100, 100);
  const image1 = new Image();

  let iconData = "";
  image1.crossOrigin = "anonymous";
  image1.onload = drawImage1;
 // image.onload = drawImage;
  let iconType = "";

  image.onload = drawImage;
  // if (ticket.icon) {
  //   Axios.post(`${process.env.VUE_APP_SERVICE_URL}planners/convert-to-base64`, {
  //     imgPath: ticket.icon,
  //   })
  //     .then((response) => {
  //       image1.src = response.data.url;
  //       iconType = response.data.type;
  //     })
  //     .then((response) => {
  //       image.src = document.getElementById("img").src;
  //     });
  // } else {
  image.src = document.getElementById("img").src;
  // }

  let divRatio, newHeight, newWidth;

  function drawImage1() {
    if (image1.height > image1.width) {
      divRatio = image1.height / ticketLogoTemplate.height;
      newHeight = ticketLogoTemplate.height;
      newWidth = image1.width / divRatio;
    } else {
      divRatio = image1.width / ticketLogoTemplate.width;
      newWidth = ticketLogoTemplate.width;
      newHeight = image1.height / divRatio;
    }

    ctx1.drawImage(this, 0, 0, newWidth, newHeight);
    iconData = ticketLogoTemplate.toDataURL();
  }
  function drawImage() {
    ticketTemplate.width = this.naturalWidth;
    ticketTemplate.height = this.naturalHeight;
    ctx.drawImage(this, 0, 0);
    this.dataUrl = ticketTemplate.toDataURL();
    doc.setFontSize(11);
    doc.addImage(this.dataUrl, "PNG", 0, 0, 212, 290, "", "FAST");
    let data = self;

    if (data.url == ticket.event_url || data.url == ticket.url) {
      let startDate = dateFormat(
          parseInt(data.start.$date.$numberLong),
          "ddd, mmm dS, yyyy, h:MM TT"
      );
      let endDate = dateFormat(
          parseInt(data.end.$date.$numberLong),
          "ddd, mmm dS, yyyy, h:MM TT"
      );
      if(data.isRecurring){
        const date = getVenueAccessDate(data.tiers, ticket.venueAccessDateID);
        startDate = dateFormat(
            parseInt(date.start.$date.$numberLong),
            "ddd, mmm dS, yyyy, h:MM TT"
        );
        endDate = dateFormat(
            parseInt(date.end.$date.$numberLong),
            "ddd, mmm dS, yyyy, h:MM TT"
        );
      }
      for (let a = 0; a < ticket.ticket_count; a++) {
        for (let t = 0; t < data.tiers.length; t++) {
          if (data.tiers[t].name == ticket.tier) {
            // PRICE
            doc.text("$" + data.tiers[t].price.toFixed(2), 96, 47);
          }
        }

        if (ticket.tickets) {
          var opts = {
            errorCorrectionLevel: "H",
            type: "image/png",
            quality: 1,
            margin: 1,
          };

          let qr;
          let obj;

          if (printTicket) {
            obj = {
              _id: self._id,
              name: ticket.name,
              orderId: ticket._id,
              validation: ticket.tickets[a].validation,
              print: true,
            };
          } else if (comps) {
            obj = {
              _id: self._id,
              name: ticket.name,
              orderId: ticket._id,
              validation: ticket.tickets[a].validation,
              comps: true,
            };
          } else {
            obj = {
              _id: self._id.$oid,
              name: ticket.name,
              orderId: ticket._id.$oid,
              validation: ticket.tickets[a].validation,
            };
          }

          let objJson = JSON.stringify(obj);
          QRCode.toDataURL(objJson, opts, function(err, url) {
            if (err) throw err;
            qr = url;
          });
          doc.addImage(qr, "png", 128, 71, 75, 75, "", "");

          // support for printing old tickets
        }
        // 197 - 35 / canvasRatio,
        // 252,
        // if (iconData && iconType) {
        //   let canvasRatio = 300 / newWidth;
        //   doc.addImage(iconData, iconType, 14, 173, 55, 50, "", "FAST");
        //   const l1 = "Refunds & Exchanges Policy:";
        //   const l2 =
        //     "We issue refunds for digital products only if allowed by ticket seller in writing. To be clear, the ticket seller must allow a refund, outside of technical issues with Ticket Tote's features. We are a merchant and trust account processing payments for the ticket seller, and do not have the authority to release refunds to ticket buyers. Do not contact Ticket Tote for refunds related to the event. You can";
        //   const l5 =
        //     "go to the event page and under the contact button reach out to the seller.";
        //   const l3 =
        //     "We recommend contacting us for help if you experience any issues receiving or downloading our tickets if you cannot find them in your junk mail or in Ticket History on Ticket Tote. If you're charged but didn't receive tickets, this is most likely a pre-authorization by the bank, and Ticket Tote hasn't charged you.";

        //   const l4 =
        //     "We do not offer refunds based on experience, as this is a subjective manner.";
        //   doc.text(l1, 72, 176, { maxWidth: 70 });
        //   doc.text(l2, 72, 183, { maxWidth: 70 });

        //   doc.text(l5, 14, 229, { maxWidth: 125 });
        //   doc.text(l3, 14, 237, { maxWidth: 125 });
        //   doc.text(l4, 14, 263, { maxWidth: 125 });
        // } else {
        const l1 = "Refunds & Exchanges Policy:";
        const l2 =
          "We issue refunds for digital products only if allowed by ticket seller in writing. To be clear, the ticket seller must allow a refund, outside of technical issues with Ticket Tote's features. We are a merchant and trust account processing payments for the ticket seller, and do not have the authority to release refunds to ticket buyers. Do not contact Ticket Tote for refunds related to the event. You can go to the event page and under the contact button reach out to the seller.";

        const l3 =
          "We recommend contacting us for help if you experience any issues receiving or downloading our tickets if you cannot find them in your junk mail or in Ticket History on Ticket Tote. If you're charged but didn't receive tickets, this is most likely a pre-authorization by the bank, and Ticket Tote hasn't charged you.";

        const l4 =
          "We do not offer refunds based on experience, as this is a subjective manner.";

        doc.text(l1, 14, 173, { maxWidth: 127 });
        doc.text(l2, 14, 181, { maxWidth: 127 });
        doc.text(l3, 14, 216, { maxWidth: 127 });
        doc.text(l4, 14, 242, { maxWidth: 127 });
        // }
        // EVENT
        doc.text(data.name, 10, 35);

        // TICKET#
        doc.text(ticket.ticketNumbers[a].toString(), 24, 24);

        if (ticket.tickets) {
          // VALIDATION
          doc.text(ticket.tickets[a].validation, 93, 24);

          // INVOICE
          doc.text(ticket.tickets[a].invoice, 163, 24);
        } else {
          // VALIDATION
          doc.text(ticket.validation, 93, 24);

          // INVOICE
          doc.text(ticket.invoice, 163, 24);
        }
        // TYPE
        doc.text(ticket.tier, 16, 47);

        // START DATE
        doc.text(startDate, 28, 60.5);

        // ENDDATE
        doc.text(endDate, 110, 60.5);

        // LOCATION
        if (data.privateVenue) {
          doc.text("Secret Venue", 25, 54);
        } else {
          let splitAddress = `${address}, ${data.city}, ${data.region_name}`;
          doc.text(splitAddress, 25, 54);
        }
        //SOLDBY
        doc.text(ticket.name, 150, 47);

        if (a < ticket.ticket_count - 1) {
          doc.addPage();
          doc.addImage(this.dataUrl, "PNG", 0, 0, 212, 290, "", "FAST");
        }
      }

      image1.src = "";
      ctx1.clearRect(0, 0, ticketLogoTemplate.width, ticketLogoTemplate.height);

      const blobPDF = new Blob([doc.output("blob")], {
        type: "application/pdf",
      });
      const blobUrl = URL.createObjectURL(blobPDF);

      const fileName = "tickets.pdf";
      const pdf = blobUrl;

      onComplete({ pdf, fileName });
    }
  }
}
export function viewWillCall(
  info,
  ticket,
  ticketTemplate,
  address,
  onComplete,
  whiteLabelId
) {
  let doc = new jsPDF({
    format: "a4",
  });

  let ctx = ticketTemplate.getContext("2d");

  const image = new Image(100, 100);
  image.onload = drawImage;

  image.src = document.getElementById("willcall").src;

  function drawImage() {
    ticketTemplate.width = this.naturalWidth;
    ticketTemplate.height = this.naturalHeight;
    ctx.drawImage(this, 0, 0);
    this.dataUrl = ticketTemplate.toDataURL();
    doc.setFontSize(22);
    doc.addImage(this.dataUrl, "PNG", 0, 0, 212, 290, "", "FAST");
    let data = ticket;

    console.log('hh', data, info);
    if (data.url == info.event_url || data.url == info.url) {
      let startDate = dateFormat(
        parseInt(data.start.$date.$numberLong),
        "ddd, mmm dS, yyyy, h:MM TT"
      );
      let endDate = dateFormat(
        parseInt(data.end.$date.$numberLong),
        "ddd, mmm dS, yyyy, h:MM TT"
      );
      if(data.isRecurring){
        const date = getVenueAccessDate(data.tiers, ticket.venueAccessDateID);
        startDate = dateFormat(
            parseInt(date.start.$date.$numberLong),
            "ddd, mmm dS, yyyy, h:MM TT"
        );
        endDate = dateFormat(
            parseInt(date.end.$date.$numberLong),
            "ddd, mmm dS, yyyy, h:MM TT"
        );
      }

      doc.setFontSize(9);
      // TICKET #
      doc.text("WILL CALL", 31, 33);
      // VALIDATION
      doc.text("WILL CALL", 91, 33);
      // INVOICE #
      doc.text("WILL CALL", 157, 33);

      // EVENT NAME
      doc.setFontSize(22);
      doc.setFont("helvetica", "", "bold");
      doc.text(data.name, 14, 44);

      doc.setFont("helvetica", "", "normal");
      doc.setFontSize(9);

      // TYPE
      doc.text(info.tier, 24, 54);

      // PRICE
      doc.text("N/A", 89, 54);

      // START DATE
      doc.text(startDate, 36, 66.5);

      // ENDDATE
      doc.text(endDate, 108, 66.5);

      if (whiteLabelId) {
        // LOCATION
        if (data.privateVenue) {
          doc.text("Secret Venue", 87, 60);
        } else {
          let splitAddress = `${address}, ${ticket.city}, ${ticket.region_name}`;
          doc.text(splitAddress, 82, 60);
        }
      } else {
        // LOCATION
        if (data.privateVenue) {
          doc.text("Secret Venue", 87, 60);
        } else {
          let splitAddress = `${address}, ${ticket.city}, ${ticket.region_name}`;
          doc.text(splitAddress, 87, 60);
        }
      }

      //ORDERED BY:
      doc.text(`${info.name} (Total#: ${info.ticket_count})`, 138, 54);

      //PICKUP LOCATION
      doc.setFontSize(16);
      doc.text(info.pickup_location, 73, 163.5);
    }

    const blobPDF = new Blob([doc.output("blob")], {
      type: "application/pdf",
    });
    const blobUrl = URL.createObjectURL(blobPDF);

    const fileName = "tickets.pdf";
    const pdf = blobUrl;

    onComplete({ pdf, fileName });
  }
}
export function canApplyDiscount(promoCode, tier) {
  if (
    promoCode.tier === "*" ||
    promoCode.tier === "All Ticket Tiers" ||
    promoCode.tier_id === tier.tier_id
  ) {
    if (
      promoCode.venue_access_date_id &&
      promoCode.venue_access_date_id !== tier.venueAccessDateId
    ) {
      return false;
    }

    if (
      promoCode.venue_access_time_id &&
      promoCode.venue_access_time_id !== tier.venueAccessTimeId
    ) {
      return false;
    }

    return true;
  }
}

export function calculateDiscount(type, amount, discount) {
  if (type === "$ Discount") {
    return discount;
  } else {
    return (amount * discount) / 100;
  }
}
